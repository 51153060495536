
import { Box, Center, UnstyledButton } from '@mantine/core';
import React, { forwardRef, MouseEventHandler } from 'react';
import classes from './MenuIcon.module.css';



interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    icon?: React.ReactNode;
    forceShow?: boolean;
}

const MenuIcon = forwardRef<HTMLButtonElement, UserButtonProps>((props, ref) => {

    const size = 20

    const { icon, forceShow, ...restProps } = props;

    return (
        <UnstyledButton ref={ref} {...restProps}>
            <Center>
                <svg className={forceShow ? classes.forceShow : ""} width={size} height={size} color='black' preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                </svg>
            </Center>
        </UnstyledButton>
    );

})

export default MenuIcon;
