import React, { useState, useRef, useEffect } from 'react';
import { Button, Box, Text, ScrollArea, Skeleton, Paper, Center } from '@mantine/core';
import classes from './ChatContent.module.css';
import Markdown from 'react-markdown'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import FunctionChatResponse from './FunctionChatResponse';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

interface ChatProps {
    messages: ChatMessage[];
    loading: boolean;
}


const ChatContent: React.FC<ChatProps> = ({ messages, loading }) => {
    const [showButton, setShowButton] = useState<boolean>(false);
    const scrollAreaRef = useRef<HTMLDivElement>(null);
    const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
    const [lastScrollPosition, setLastScrollPosition] = useState({ x: 0, y: 0 });
    const [autoScroll, setAutoScroll] = useState(true);
    const [disableScroll, setDisableScroll] = useState(false); // Disable scrollToBottom until reset
    const [lastScrollHeight, setLastScrollHeight] = useState<number>(0);





    // Monitor changes to autoScroll and disable scrolling if it turns false
    useEffect(() => {
        if (!autoScroll) {
            setDisableScroll(true); // Disable scrolling indefinitely
        }
    }, [autoScroll]);

    useEffect(() => {

        if (scrollAreaRef?.current?.scrollHeight === lastScrollHeight){
            return;
        } else {
            if (scrollAreaRef?.current?.scrollHeight){
                setLastScrollHeight(scrollAreaRef.current.scrollHeight);
            }
        }

        if (!disableScroll && autoScroll) {
            scrollToBottom();
        }
    }, [messages]);


    useEffect(() => {
        if (scrollPosition.y < lastScrollPosition.y) {
            setAutoScroll(false)
            setDisableScroll(true)
        }
        setLastScrollPosition(scrollPosition)
        handleScroll(); // Initial check
    }, [scrollPosition]);

    const scrollToBottom = () => {
        setAutoScroll(true)
        if (scrollAreaRef.current) {
            scrollAreaRef.current.scrollTo({ top: scrollAreaRef.current.scrollHeight, behavior: 'smooth' });
        }
    };

    const handleScroll = () => {
        if (scrollAreaRef.current) {
            const GRACE_AREA = 85; // Grace area in pixels

            const { scrollTop, scrollHeight, clientHeight } = scrollAreaRef.current;
            const isNearBottom = scrollTop + clientHeight >= scrollHeight - GRACE_AREA;
            const isAtBottomBottom = scrollTop + clientHeight >= scrollHeight;
            setShowButton(!isNearBottom);
            if (isAtBottomBottom) {
                setAutoScroll(true);
                setDisableScroll(false)
            }
        }
    };

    return (
        <div className={classes.scrollContainer}>
            <ScrollArea style={{ height: '100%' }} scrollbars="y" scrollbarSize={4} viewportRef={scrollAreaRef} onScrollPositionChange={onScrollPositionChange}>
                <Center>
                    <Box style={{ maxWidth: "768px", minWidth: "768px" }} p="md">
                        <div>
                            {messages.map((message, index) => (
                                <div key={message.id}>
                                    {message.message.role === 'user' ?
                                        <Box style={{ textAlign: 'right' }}>
                                            <Box mb={38} bg={'fnchatblue'} style={{
                                                borderTopLeftRadius: '20px',
                                                borderTopRightRadius: '20px',
                                                borderBottomLeftRadius: '20px',
                                                borderBottomRightRadius: '0px',
                                                display: 'inline-block',
                                                maxWidth: "60%"
                                            }} p='xs'>
                                                <Text c={'white'} ta={"right"}>{message.message.content}</Text>
                                            </Box>
                                        </Box>
                                        :
                                        <>
                                            <FunctionChatResponse message={message.message.content} />
                                        </>
                                    }
                                </div>
                            ))}
                        </div>
                    </Box>
                </Center>
            </ScrollArea>
            {showButton && (
                <div className={classes.buttonContainer}>
                    <Button variant="solid" bg={'fnchatblue'} onClick={scrollToBottom} leftSection={<ArrowDownIcon />}>
                        Scroll to Bottom
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ChatContent;
