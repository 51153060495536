import { Menu } from '@mantine/core';
import * as React from 'react';
import MenuIcon from '../Icons/MenuIcon';
import TrashIcon from '../Icons/TrashIcon';
import { supaBaseDeleteSession } from '@/utility/Utilities';
import useReloadStore, { ReloadStoreProps } from '@/GlobalState';
import { notifications } from '@mantine/notifications';


interface Props {
    session: ChatSession;
    newChat: () => void;
}

const NavBarSessionMenu: React.FC<Props> = ({ session, newChat }) => {
    const [leaveOpen, setLeaveOpen] = React.useState(false);

    const triggerReloadSessions = useReloadStore((state) => (state as ReloadStoreProps).triggerReloadSessions);

    const deleteItem = async () => {

        const deleteStatus = await supaBaseDeleteSession(session.id);

        console.log("Delete Status: ", deleteStatus)

        if (deleteStatus) {
            if (triggerReloadSessions) {
                triggerReloadSessions();
            }

            notifications.show({
                title: 'Success',
                color: 'green',
                message: 'Deleted Chat!',
                position: 'top-center'
            })


            newChat();
        } else {

            notifications.show({
                title: 'Error',
                color: 'red',
                message: 'Failed to delete chat!',
                position: 'top-center'
            })

        }


    };

    return (
        <Menu onOpen={() => setLeaveOpen(true)} onClose={() => setLeaveOpen(false)}>
            <Menu.Target >
                <MenuIcon forceShow={leaveOpen} style={{ paddingRight: "10px" }} />
                {/* <Button> Hi </Button> */}
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item onClick={deleteItem} color="red" leftSection={<TrashIcon />}>
                    Delete
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};

export default NavBarSessionMenu;
