// reloadStore.js
import {create} from 'zustand';

export type ReloadStoreProps = {
  reloadSessions?: boolean,
  triggerReloadSessions?: () => void
};


const useReloadStore = create((set) => ({
  reloadSessions: false,
  triggerReloadSessions: () => set((state: ReloadStoreProps) => ({ reloadSessions: !state.reloadSessions })),
}));

export default useReloadStore;